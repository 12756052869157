<template>
    <b-container>
        <h3>dfgdfg</h3>
        <b-list-group class="mt-5">
            <b-list-group-item v-if="currentPath != ''">
                <div class="fcol fcenter">
                    <a href="javascript:;" @click="previousFolder()" class='mb-0'>
                        <i class="bi bi-arrow-90deg-left"></i> Précedent
                    </a>
                </div>
            </b-list-group-item>
            <b-list-group-item v-for="dir in fileList.dirs" v-bind:key="dir">
                <div class="fcol fcenter" @click="changeCurrentDir(dir)">
                    <a href="javascript:;"  class='mb-0'>
                        <i class="bi bi-folder"></i> {{dir}}
                    </a>
                </div>
            </b-list-group-item>
            <template v-for="file in fileList.files" >
                <b-list-group-item v-bind:key="file" v-if="checkFileExtension(file)">
                    <div class="frow fcenter">
                        <div class="fcol fcenter">
                            <i v-if="isSubtitle(file)" class="bi bi-chat-dots"></i>
                            <i v-else class="bi bi-camera-reels"></i>
                        </div>
                        <div class="fcol fcenter pl-3">
                            <router-link class='fcol fcenter' :to="getWatchLink(file)" v-if="isStreamable(file)">
                                {{cleanName(file)}}
                            </router-link>
                            <a v-else :href="getDownloadLink(file)" target="blank" :title="file">
                                {{cleanName(file)}}
                            </a>
                            <div>
                                <b-badge variant="success" v-if="isStreamable(file)">Streamable</b-badge>
                                <b-badge variant="warning" v-else>Téléchargement seulement</b-badge>
                            </div>
                        </div>
                    </div>
                    <div class="fcol fcenter">
                        <b-dropdown id="dropdown-left" text="Plus" variant="outline-primary" class="m-2">
                            <b-dropdown-item :to="getWatchLink(file)">Regarder</b-dropdown-item>
                            <b-dropdown-item :href="getDownloadLink(file)">Télécharger</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </b-list-group-item>
            </template>
        </b-list-group>
    </b-container>
</template>
<script>
import { join, extname, basename } from "path-browserify";
import { mapState } from "vuex";
export default {
    name: 'FileExplorer',
    data: function() {
        return {
            fileList: []
        }
    },
    computed: {
        ...mapState(['key', 'currentPath'])
    },
    methods: {
        cleanName(video) {
            return basename(video).replace(extname(video), "");
        },
        isStreamable(video) {
            return [
                ".mp4"
            ].indexOf(extname(video).toLowerCase()) !== -1;
        },
        isSubtitle(video) {
            return [
                ".srt",
                ".txt",
                ".sub"
            ].indexOf(extname(video).toLowerCase()) !== -1;
        },
        checkFileExtension(video) {
            return [
                ".srt",
                ".txt",
                ".sub",
                ".wav",
                ".mp3",
                ".mp4", 
                ".avi",
                ".mkv",
            ].indexOf(extname(video).toLowerCase()) !== -1;
        },
        getWatchLink(newDir) {
            return '/watch/?video=' + encodeURIComponent(join(this.currentPath, newDir));
        },
        getDownloadLink(newDir) {
            return this.$baseurl + "/file/download/?video=" + encodeURIComponent(join(this.currentPath, newDir)) + "&key=" + this.key;
        },
        async previousFolder() {
            let parts = this.currentPath.split("/")
            this.$store.dispatch('updateCurentPath', parts.slice(null, -1).join("/", parts));
            await this.updateList()
        },
        async changeCurrentDir(newDir) {
            const newdir = join(this.currentPath,  newDir);
            this.$store.dispatch('updateCurentPath', newdir);
            await this.updateList()
        },
        async updateList() {
            const files = await this.$api.get("/file/path?requestpath=" + encodeURIComponent(this.currentPath));
            this.fileList = files.data;
        }
    },
    async mounted() {
        await this.updateList();
    }
}
</script>