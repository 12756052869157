<template>
  <div class="watch">
    <b-button variant="outline-warning" to="/explore" class="mt-2 mb-2 pt-2 pb-2">Retour</b-button>
    <video-player :options="videoOptions" v-if="this.videoOptions.sources.length > 0"/>
  </div>
</template>

<script>
import VideoPlayer from '@/components/VideoPlayer.vue';
import { join } from "path-browserify";
import { mapState } from 'vuex';


export default {
    name: 'WatchView',
    components: {VideoPlayer},
    props: {
        options: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    computed: {
        ...mapState(['key'])
    },
    data() {
        return {
            videoOptions: {
                autoplay: true,
                controls: true,
                sources: []
            }
        }
    },
    mounted() {
        this.videoOptions.sources.push({
            src : this.$baseurl + join("/file/buffer/") + "?key=" + this.key + "&requestpath=" + encodeURIComponent(this.$route.query.video),
            type: "video/mp4"
        })
    }
}
</script>
