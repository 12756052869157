<template>
  <div class="home">
    <FileExplorer/>
  </div>
</template>

<script>
// @ is an alias to /src
import FileExplorer from '@/components/FileExplorer.vue'

export default {
  name: 'ExplorerView',
  components: {
    FileExplorer
  }
}
</script>
