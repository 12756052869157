import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    key: localStorage.getItem("apiKey"),
    currentPath: "",
  },
  getters: {
    getApiKey(state) {
      return state.key
    }
  },
  mutations: {
    setApiKey(state, payload) {
      state.key = String(payload)
      localStorage.setItem("apiKey", payload)
    },
    setCurentPath(state, payload) {
      state.currentPath = String(payload)
    }
  },
  actions: {
    updateApiKey({commit}, key) {
      commit('setApiKey', key)
    },
    updateCurentPath({commit}, path) {
      commit('setCurentPath', path)
    }
  },
  modules: {
  }
})
