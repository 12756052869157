import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue } from 'bootstrap-vue'

import './scss/app.scss'
import store from './store'
import router from './router'
import axios from 'axios'

Vue.use(BootstrapVue)


Vue.prototype.$baseurl = 'https://media-share-api.arnaudtriolet.fr';
var api = axios.create({
  baseURL: Vue.prototype.$baseurl
});

const localKey = localStorage.getItem("apiKey");
if (localKey !== "" || localKey !== undefined || localKey !== null) {
  await api.get("/login/check/" + localKey).then(function(response){
    if (response.data === true) {
      api.defaults.headers.get['Key'] = localKey
    }
  })
}

Vue.prototype.$api = api;
Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
