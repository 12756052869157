<template>
    <b-row class="justify-content-center mt-5">
        <b-col cols="9">
            <b-card>
                <h1>Login</h1>

                <b-card bg-variant="danger" text-variant="white" v-if="hasFail">
                    Adresse non autorisée
                </b-card>

                <b-form @submit.prevent="check">
                    <b-form-input
                        id="email"
                        v-model="email"
                        type="email"
                        placeholder="Enter email"
                        class="mt-3"
                        required
                    ></b-form-input>
                    <b-button type="submit" class="mt-3">
                        Connexion
                    </b-button>
                </b-form>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {SHA1} from "crypto-js"
import { mapState } from 'vuex'

export default {
    name: 'HomeView',
    data: () => {
        return {
            hasFail: false,
            email: ""
        }
    },
    computed: {
        ...mapState(['key'])
    },
    methods: {
        async isValidToken(hash) {
            let response = await this.$api.get("/login/check/" + hash);
            return response.data === true;
        },
        async check() {
            const hash = SHA1(this.email);
            var that = this;
            if (this.isValidToken(hash)) {
                this.hasFail = false
                this.$store.dispatch('updateApiKey', hash);
                that.$api.defaults.headers.get['Key'] = hash;
                this.$router.push({ name: 'explore' });
            } else {
                this.hasFail = true
            }
        }
    },
    mounted() {
        // if (this.key !== "" || this.key !== undefined || this.key !== null) {
        //     if (this.isValidToken(this.key)) {
        //         this.$router.push({name: "explore"})
        //     }
        // }
    }
}
</script>
