import Vue from 'vue'
import VueRouter from 'vue-router'
import ExplorerView from '../views/ExplorerView.vue'
import WatchView from '../views/WatchView.vue'
import LoginView from '../views/LoginView.vue'
import axios from 'axios'

Vue.use(VueRouter)

const checkApiKey = (async (to, from, next) => {
  const resp = await axios.get(Vue.prototype.$baseurl + "/login/check/"+ localStorage.getItem("apiKey"));
  if (resp.data !== true) {
    next({ name: "login" });
    return;
  }
  
  next();
  return;
})

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/explore',
    name: 'explore',
    component: ExplorerView,
    beforeEnter: checkApiKey,
  },
  {
    path: '/watch/',
    name: 'watch',
    component: WatchView,
    beforeEnter: checkApiKey,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
